var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card-title',[(_vm.edit && _vm.entityInFocus)?_c('h2',[_vm._v(" "+_vm._s(_vm.$t("organizationStructure.entityModal.modalTitle.edit", { entity: _vm.calculateUILocaleString({ languageTexts: _vm.entityInFocus.name.languageTexts, }), }))+" ")]):_c('h2',[_vm._v(" "+_vm._s(_vm.$t("organizationStructure.entityModal.modalTitle.create", { entity: _vm.calculateUILocaleString({ languageTexts: _vm.level.name.languageTexts, }), }))+" ")])]),(_vm.edit)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("organizationStructure.entityModal.modalDescription.edit"))+" ")]):_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("organizationStructure.entityModal.modalDescription.create"))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0 px-0 mb-3 px-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('LocaleTextBox',{key:_vm.rerenderNameLocaleTextBox,attrs:{"labelPrefixI18nSelector":"organizationStructure.entityModal.name","passPayloadToI18n":{
              entity: _vm.calculateUILocaleString({
                languageTexts: _vm.level.name.languageTexts,
              }),
            },"initVal":_vm.name},on:{"res":_vm.nameUpdatedHandler},scopedSlots:_vm._u([{key:"text-input",fn:function(slotProps){return [_c('v-text-field',{attrs:{"autofocus":"","required":"","outlined":"","dense":"","label":slotProps.label},on:{"input":slotProps.inputHandler},model:{value:(slotProps.model),callback:function ($$v) {_vm.$set(slotProps, "model", $$v)},expression:"slotProps.model"}})]}}])}),_c('LocaleTextBox',{key:_vm.rerenderDescriptionLocaleTextBox,attrs:{"labelPrefixI18nSelector":"organizationStructure.entityModal.description","passPayloadToI18n":{
              entity: _vm.calculateUILocaleString({
                languageTexts: _vm.level.name.languageTexts,
              }),
            },"initVal":_vm.description},on:{"res":_vm.descriptionUpdatedHandler},scopedSlots:_vm._u([{key:"text-input",fn:function(slotProps){return [_c('v-textarea',{attrs:{"label":slotProps.label,"required":"","outlined":"","dense":""},on:{"input":slotProps.inputHandler},model:{value:(slotProps.model),callback:function ($$v) {_vm.$set(slotProps, "model", $$v)},expression:"slotProps.model"}})]}}])}),(_vm.allEntitiesOfUpperLevel.length > 0)?_c('v-select',{attrs:{"items":_vm.allEntitiesOfUpperLevel,"item-value":"id","label":_vm.$t('organizationStructure.entityModal.upperEntityLabel', {
                entity: _vm.calculateUILocaleString({
                  languageTexts: _vm.upperLevelName.languageTexts,
                }),
              }),"dense":"","outlined":"","persistent-hint":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.calculateUILocaleString({ languageTexts: data.item.name.languageTexts, }))+" ")]}},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.calculateUILocaleString({ languageTexts: data.item.name.languageTexts, }))+" ")]}}],null,false,2355126108),model:{value:(_vm.parentEntityID),callback:function ($$v) {_vm.parentEntityID=$$v},expression:"parentEntityID"}}):_vm._e()],1),_c('v-col',{staticClass:"pb-0 px-0 mb-3 px-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ImgFromS3',{key:_vm.rerenderImgFromS3,staticClass:"mb-4",attrs:{"assumedSrc":_vm.assumedSrc,"dataType":"entity"},scopedSlots:_vm._u([{key:"v-img",fn:function(slotProps){return [_c('v-img',{attrs:{"max-height":"200px","src":slotProps.src}},[_c('v-btn',{staticClass:"iv-edit-icon",attrs:{"color":"primary"},on:{"click":_vm.selectImg}},[_c('v-icon',{attrs:{"color":"darken-2"}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v(" "+_vm._s(_vm.edit ? _vm.$t("general.editImage") : _vm.$t("general.addImage"))+" ")])],1),_c('FileInput',{ref:"img-upload",staticStyle:{"display":"none"},attrs:{"acceptedType":'image/png'}})],1)]}}])}),(_vm.level.customData.length > 0)?_c('div',[_c('v-divider'),_c('v-card-title',{staticClass:"pt-0 pt-sm-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("organizationStructure.levelModal.customData.title"))+" ")])]),_vm._l((_vm.customData),function(customDatum){return _c('div',{key:customDatum.customDataID},[_c('v-text-field',{attrs:{"label":`${_vm.calculateUILocaleString({
                  languageTexts: customDatum.name.languageTexts,
                })}
                  (${_vm.$t(
                    'organizationStructure.levelModal.customData.types.' +
                      customDatum.type
                  )})`,"outlined":"","rules":customDatum.type === _vm.Type.INT ? _vm.rules.correctNumber : []},on:{"keypress":function($event){if (customDatum.type === _vm.Type.INT) {
                    _vm.isNumber($event);
                    _vm.$refs.form.validate();
                  }}},model:{value:(customDatum.value),callback:function ($$v) {_vm.$set(customDatum, "value", $$v)},expression:"customDatum.value"}})],1)}),_c('v-divider')],2):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[(_vm.edit)?_c('v-btn',{attrs:{"x-large":"","color":"warning","text":"","disabled":_vm.isDeleteDisabled},on:{"click":_vm.deleteHandler}},[_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" "),_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-delete ")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"x-large":"","color":"secondary","text":""},on:{"click":_vm.closeHandler}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"x-large":"","type":"submit","color":"primary","text":"","disabled":_vm.isSubmitDisabled},on:{"click":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }