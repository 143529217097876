import { render, staticRenderFns } from "./LevelModalRead.vue?vue&type=template&id=687c4ac2&"
import script from "./LevelModalRead.vue?vue&type=script&lang=js&"
export * from "./LevelModalRead.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports