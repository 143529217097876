/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ybctz7ij4b.execute-api.eu-central-1.amazonaws.com/release",
            "region": "eu-central-1"
        },
        {
            "name": "nisabaUserManagementApi",
            "endpoint": "https://8rx73fdj12.execute-api.eu-central-1.amazonaws.com/release",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://m66grhm7u5amrcqll4yhket22y.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tiepkondybggxblizgnyeb43x4",
    "aws_cognito_identity_pool_id": "eu-central-1:0d9bb648-0da7-41c7-b265-0d9f897ee132",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_WhAI8XjdE",
    "aws_user_pools_web_client_id": "15ec4spbvhtas59rmp0q67g3dg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "nisabareleasebucket21818-release",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
